<template>
  <div class="con">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="add-food">
        <van-form @submit="onSubmit">
          <van-field
            v-model="name"
            type="text"
            name="name"
            label="员工姓名"
            placeholder="请输入员工姓名"
            :rules="[{ required: true }]"
            :disabled="shop.user_model != 1"
          />
          <van-field name="status" label="状态" :disabled="shop.user_model != 1">
            <template #input>
              <van-radio-group v-model="status" direction="horizontal" :disabled="shop.user_model != 1">
                <van-radio :name="1">正常</van-radio>
                <van-radio :name="2">禁用</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="phone"
            type="number"
            name="phone"
            label="员工电话"
            placeholder="请输入员工电话"
            :rules="[{ required: true }]"
            :disabled="shop.user_model != 1"
          />
          <van-field
            v-model="account"
            type="text"
            name="account"
            label="员工账号"
            placeholder="请输入员工账号"
            :rules="[{ required: true }]"
            :disabled="shop.user_model != 1"
          />
          <van-field
            v-model="password"
            type="password"
            name="password"
            label="员工密码"
            placeholder="请输入员工密码"
            :disabled="shop.user_model != 1"
          />
          <div style="padding: 3rem 0" class="flex flex-jc" v-if="shop.user_model == 1">
            <van-button
              round
              block
              type="danger"
              class="editdesk-btn"
              style="margin-right: 6rem; background: #eaeaea; color: #666666"
              native-type="button"
              @click="goBack"
              >取消
            </van-button>
            <van-button
              round
              block
              type="info"
              native-type="submit"
              class="editdesk-btn"
              >保存</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
export default {
  name: "addWorker",
  components: {
    titlebar,
  },
  data() {
    return {
      name: "", // 员工姓名
      status: 1, // 员工状态
      phone: "", //员工电话
      account: "", //员工账号
      password: "", // 员工密码
      page_name: '',
      id: '',
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'})
  },
  async created() {
    this.page_name = this.$route.query.name || '';
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.setInfo();
    }
  },
  methods: {
    setInfo() {
      const info = this.$route.params.workerinfo || {};
      console.log(info);
      this.name = info.staff_name || '';
      this.status = info.status ? info.status : '';
      this.phone = info.staff_mobile || '';
      this.account = info.staff_account || '';
    },
    goBack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: this.$route.query.active,
        },
      })
    },
    // 提交
    onSubmit(e) {
      if (this.name == '') {
        this.$toast('请输入姓名');
        return;
      }
      if (this.phone == '') {
        this.$toast('员工电话');
        return;
      }
      if (this.account == '') {
        this.$toast('请输入员工账号');
        return;
      }
      if (!this.id && this.password == '') {
        this.$toast('请输入密码');
        return;
      }
      let data = {
        staff_name: this.name,
        staff_account: this.account,
        staff_mobile: this.phone,
        status: this.status,
        shop_id: localStorage.getItem("shop_id"),
      };
      if (this.password != '') {
        data.staff_paw = this.password;
      }
      if (this.id) {
        data.t = 2;
        data.staff_id = this.id;
      } else {
        data.t = 1;
      }
      this.setStaffAction(data);
    },
    //员工管理 添加/编辑/删除员工
    setStaffAction(data) {
      this.$api.staffAction(data).then((res) => {
        if(res.code == 1) {
          this.$toast("修改成功！");
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>

.desk-right {
  height: calc(100% - 10.6rem);
  background-color: #fff;
  margin-top: 1.5rem;

  .add-food {
    height: 100%;
    margin-top: 2rem;
    overflow: scroll;
    overflow-x: hidden;

    .van-cell.van-field {
      font-size: 2.6rem;
      padding: 2rem 3rem;

      /deep/.van-cell__title span {
        line-height: 6rem;
      }

      /deep/.van-field__control {
        height: 6rem;

        .van-radio__icon {
          font-size: 2.6rem;
        }

        .van-radio__icon--checked {
          font-size: 2.6rem;

          .van-icon {
            background-color: #1588F5;
            border-color: #1588F5;
          }
        }
      }
    }

    .van-cell.van-field {
      font-size: 2.6rem;

      /deep/textarea.van-field__control::placeholder {
        transform: translateY(0.1rem);
      }

      /deep/.van-field__word-limit {
        font-size: 2.4rem;
      }
    }

    .van-button--normal {
      font-size: 2.6rem;
      background-color: #1588F5;
      border-color: #1588F5;
    }
  }
}

.editdesk-btn {
  width: 42rem;
  height: 8rem;
  border-radius: 0.8rem;
  border: 0;
}

.con {
  height: 100%;
}
</style>
